import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactInputVerificationCode from 'react-input-verification-code'
import { space, fontSizes, fontWeights, radius } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ErrorLabel from 'components/atoms/ErrorLabel'

const StyledReactInputVerificationCode = styled(Flex)`
  justify-content: center;

  --ReactInputVerificationCode-itemWidth: 40px;
  --ReactInputVerificationCode-itemHeight: 48px;
  --ReactInputVerificationCode-itemSpacing: ${space.s};

  .ReactInputVerificationCode__item {
    font-size: ${fontSizes.m};
    font-weight: ${fontWeights.medium};
    color: ${({ theme }) => theme.colors.primaryText};

    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ isInvalid, theme }) => (isInvalid ? theme.colors.error : theme.colors.border_color)};
    border-radius: ${radius.m};
    box-shadow: none;
  }

  .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.primaryText};
  }
`

const VerificationCodeInput = ({ error, label, value, onChange, onCompleted }) => {
  return (
    <Flex flexDirection="column" position="relative">
      {label && (
        <Text mb="xs" fontSize="s">
          {label}
        </Text>
      )}
      <StyledReactInputVerificationCode isInvalid={error}>
        <ReactInputVerificationCode
          autoFocus
          value={value}
          placeholder={null}
          length={6}
          onChange={onChange}
          onCompleted={onCompleted}
        />
      </StyledReactInputVerificationCode>
      {error && <ErrorLabel mt="xxs" textAlign="center" error={error} />}
    </Flex>
  )
}

VerificationCodeInput.defaultProps = {
  error: null,
  label: null,
  value: '',
}

VerificationCodeInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
}

export default VerificationCodeInput
