import React, { createRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import Alert from 'react-s-alert'
import Cookies from 'js-cookie'
import moment from 'moment-timezone'
import ReCAPTCHA from 'react-google-recaptcha'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { space, fontSizes } from 'theme'
import { ERROR_MESSAGE, ADMIN } from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import withMeta from 'utils/withMeta'
import withGoogleLogin from 'utils/withGoogleLogin'
import errorHelper from 'utils/errorHelper'
import FullScreen from 'shared/layouts/FullScreen'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import Link from 'components/atoms/Link'
import Icon from 'components/atoms/Icon'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import VerificationCodeInput from 'components/molecules/VerificationCodeInput'
import GoogleLogin from 'components/organisms/GoogleLogin'

// const LINKED_AUTH_URL = `/api/linkedin/auth`
// const FACEBOOK_AUTH_URL = `/api/facebook/auth`

const { EMAIL, PASSWORD, CODE } = {
  PASSWORD: 'password',
  EMAIL: 'email',
  CODE: 'code',
}

export const ValidationSchema = Yup.object().shape({
  [EMAIL]: Yup.string()
    .required('Email is required')
    .email('Must be a valid email address'),
  [PASSWORD]: Yup.string().required('Password is required'),
})

export const OtpValidationSchema = Yup.object().shape({
  [CODE]: Yup.string().required('Verification code is required'),
})

const StyledForm = styled.form`
  margin-top: ${space.m};
`

const BgImg = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  z-index: 0;
`

const StyledButtonWithLoading = styled(ButtonWithLoading)`
  height: ${pxToRem(52)} !important;
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const StyledInput = styled(Input)`
  border: 1px solid #b6cef0;
  height: ${pxToRem(54)};
`

const StyledFeatureWrapper = styled(Flex)`
  cursor: ${({ hasCursor }) => (hasCursor ? 'pointer' : 'initial')};
`

const StyledNewFeatureWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 46px;
`

const FooterLink = styled(Link)`
  font-size: ${fontSizes.xs};
  margin: 0;
  padding 0;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.secondaryText};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const FEATURES = [
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/win.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/ai-social-media-text-generator/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/aipowered.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/ai-social-media-text-generator/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/effortless.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/ai-social-media-text-generator/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/doneshia.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/ai-social-media-text-generator/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/snapchat.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/best-snapchat-scheduling-tool-vista-social/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/collabtags.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/schedule-collab-posts-on-instagram/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_features.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: true, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_feature_ai_assistant.webp', // in this folder we have one more image. Image is required!
  //   title: 'AI Social Media Text Generator to Create Better Posts 10x Faster', // title
  //   description: `Looking to create better social media posts 10x faster? Then, Vista Social’s AI social media text generator powered by ChatGPT is for you!`,
  //   link: 'https://vistasocial.com/insights/ai-social-media-text-generator/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: true, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_feature_trending_sounds.webp', // in this folder we have one more image. Image is required!
  //   title: 'Trending sounds are here!', // title
  //   description: `Happy to announce that you can now schedule Reels & TikToks with trending audio using Vista Social`,
  //   link: 'https://vistasocial.com/insights/trending-audio-in-vista-social/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: true, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_feature_instagram_and_facebook_reels.webp', // in this folder we have one more image. Image is required!
  //   title: 'Direct Publishing of Instagram and Facebook Reels!', // title
  //   description:
  //     'Happy to announce that direct publishing of Instagram and Facebok Reels is now available with Vista Social!',
  //   link: 'https://vistasocial.com/insights/how-to-schedule-facebook-reels-with-vista-social/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: true, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_feature_instagram_reels.webp', // in this folder we have one more image. Image is required!
  //   title: 'Direct Publishing of Instagram Reels!', // title
  //   description: 'Happy to announce that direct publishing of Instagram Reels is now available with Vista Social!',
  //   link: 'https://support.vistasocial.com/hc/en-us/articles/7117217001115-Instagram-direct-publishing-of-reels',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: true, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_feature_tiktok.webp', // in this folder we have one more image. Image is required!
  //   title: 'Introducing: Direct TikTok Posting', // title
  //   description: 'Happy to announce full support for direct posting of videos to Instagram!',
  //   link: 'https://vistasocial.com/insights/how-to-schedule-tiktok-posts-with-vista-socials-all-in-one-platform/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: true, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/new_feature_instagram.webp', // in this folder we have one more image. Image is required!
  //   title: 'Introducing: Instagram Carousel Posting', // title
  //   description: 'Happy to announce full support for posting image and video carousel posts to Instagram!', // description
  //   link: 'hhttps://vistasocial.com/insights/how-to-schedule-carousel-posts-on-instagram-with-vista-social/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/zapier.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/organize-and-manage-social-media-post-ideas-with-ideas-by-vista-social/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/podcast.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link:
  //     'https://vistasocial.com/beyond-social-podcast/?utm_source=vs+website&utm_medium=vs+sign+in+page&utm_campaign=beyond+social+podcast',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/threads.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link:
  //     'https://vistasocial.com/insights/vista-social-supports-threads-scheduling-unlock-new-potential-for-social-media-management/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/vistapage.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/vista-page/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/advocacy.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/employee-advocacy/',
  // },

  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/ideas.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/organize-and-manage-social-media-post-ideas-with-ideas-by-vista-social/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/collab.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/how-to-add-a-collaborator-on-instagram/',
  // },
  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/bluesky.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/insights/vista-social-supports-bluesky/',
  // },

  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/labels.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://support.vistasocial.com/hc/en-us/articles/5349355497883-How-to-create-and-manage-post-labels',
  // },

  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/theme.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://support.vistasocial.com/hc/en-us/articles/32074033410459-How-to-enable-dark-mode-in-Vista-Social',
  // },

  // {
  //   isVisible: true, // shown on the right or not displayed
  //   isNew: false, // shows the button "new feature"
  //   image: '/assets/vistasocial/login/vistapage.webp', // in this folder we have one more image. Image is required!
  //   title: '', // title
  //   description: ``,
  //   link: 'https://vistasocial.com/vista-page/',
  // },

  {
    isVisible: true, // shown on the right or not displayed
    isNew: false, // shows the button "new feature"
    image: '/assets/vistasocial/login/listening.webp', // in this folder we have one more image. Image is required!
    title: '', // title
    description: ``,
    link: 'https://vistasocial.com/social-media-listening/',
  },
]

class Login extends React.Component {
  state = {
    isSubmitting: false,
    otp: false,
    gotoAfterLogin: null,
    userType: null,
    error: '',
  }

  constructor(props) {
    super(props)
    this.formRef = createRef()
    this.verifyCode = this.verifyCode.bind(this)
  }

  static getInitialProps = async (context) => {
    const { loggedIn, verified, otp, type } = await request({ context, path: 'status' })

    let isCaptchaEnabled = true

    if (loggedIn) {
      if (otp && !verified) {
        return { otp: true }
      } else if (type && type.indexOf(ADMIN) === 0) {
        if (context.res) {
          context.res.redirect('/admin/users')
        } else {
          Router.push('/admin/users')
        }
      } else if (context.res) {
        context.res.redirect('/dashboard')
      } else {
        Router.push('/dashboard')
      }
    } else if (context.res) {
      isCaptchaEnabled = !!process.env.CAPTCHA_SECRET
    } else {
      const { enabled } = await request({ context, path: 'captcha' })

      isCaptchaEnabled = enabled
    }

    return { loggedIn, isCaptchaEnabled }
  }

  componentDidMount() {
    this.recaptchaRef = React.createRef()

    // const { query = {}, pathname } = Router.router || {}

    // if (Object.keys(query).length > 0) {
    //   Router.push(pathname, undefined, { shallow: true })
    // }

    const { otp } = this.props

    this.setState({ otp })

    request({ path: 'alert' }).then((alert) => {
      const { type, message } = alert || {}

      if (type) {
        Alert[type](message, { timeout: 5000 })
      }
    })
  }

  onRecaptchaExpired = () => {
    this.recaptchaRef.current.execute()
  }

  handleSubmit = async (values) => {
    try {
      let recaptcha

      if (this.props.isCaptchaEnabled && this.recaptchaRef && this.recaptchaRef.current) {
        recaptcha = this.recaptchaRef.current.getValue()

        if (!recaptcha) {
          await this.recaptchaRef.current.executeAsync()

          recaptcha = this.recaptchaRef.current.getValue()

          this.recaptchaRef.current.reset()
        }
      } else {
        recaptcha = 'disabled'
      }

      if (!recaptcha || recaptcha === 'disabled') {
        Alert.error(`Error verifying reCAPTCHA, please try again or contact support.`, { timeout: 5000 })
      } else {
        this.setState({ isSubmitting: true, error: '' })

        const response = await request({
          path: 'login',
          method: 'POST',
          body: {
            [EMAIL]: values[EMAIL],
            [PASSWORD]: values[PASSWORD],
            recaptcha,
          },
        })

        const { token, error, type, otp, gotoAfterLogin } = response

        if (error) {
          this.setState({ isSubmitting: false })
          // Alert.error(error, { timeout: 5000 })

          this.setState({ error })
        } else {
          if (token) {
            Cookies.set('jwt', token, { expires: 30, secure: true })
          }

          if (otp) {
            this.setState({ otp: true, isSubmitting: false, gotoAfterLogin, userType: type })
          } else if (gotoAfterLogin && type !== ADMIN) {
            window.location.href = gotoAfterLogin
          } else if (type.indexOf(ADMIN) === 0) {
            Router.push('/admin/users')
          } else {
            window.location.href = '/dashboard'
          }
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: Login.displayName, functionName: 'handleSubmit' })
      this.setState({ isSubmitting: false, error: ERROR_MESSAGE })
    }
  }

  async verifyCode(values) {
    try {
      this.setState({ isSubmitting: true, error: '' })

      const response = await request({
        path: 'me/otp',
        method: 'POST',
        body: {
          otp_code: values[CODE],
        },
      })

      const { error } = response

      if (error) {
        // Alert.error(error, { timeout: 5000 })
        this.setState({ isSubmitting: false, error })
        this.formRef.current.setFieldValue(CODE, '')
      } else {
        const { gotoAfterLogin, userType } = this.state

        if (gotoAfterLogin && userType !== ADMIN) {
          window.location.href = gotoAfterLogin
        } else if (userType && userType.indexOf(ADMIN) === 0) {
          Router.push('/admin/users')
        } else {
          window.location.href = '/dashboard'
        }

        window.location.href = '/dashboard'
      }
    } catch (error) {
      errorHelper({ error, componentName: Login.displayName, functionName: 'verifyCode' })
      this.setState({ isSubmitting: false, error: ERROR_MESSAGE })
    }
  }

  // eslint-disable-next-line react/sort-comp

  render() {
    const { loggedIn, isCaptchaEnabled } = this.props

    const FEATURE = FEATURES[moment().dayOfYear() % FEATURES.length]

    const { isSubmitting, otp, error } = this.state
    if (!loggedIn) {
      const FooterTemplate = (
        <Fragment>
          <FooterLink as={Link} href="/privacy/">
            Privacy Policy
          </FooterLink>
          <Text as="span" fontWeight="normal" fontSize="xs" px="s">
            {' | '}
          </Text>
          <FooterLink as={Link} href="/terms/">
            Terms of Service
          </FooterLink>
          <Text as="span" fontWeight="normal" fontSize="xs" px="s">
            {' | '}
          </Text>
          <FooterLink as={Link} href="/security/">
            Security
          </FooterLink>
        </Fragment>
      )

      return (
        <FullScreen>
          <Flex
            position="relative"
            width="100%"
            height="100%"
            bg="background_card_secondary"
            flexDirection={{ mobile: 'column', tablet: 'row' }}
          >
            <Flex
              width={{ mobile: '100%', tablet: FEATURE.isVisible ? '50%' : '100%' }}
              px={{ mobile: 0, tablet: 0 }}
              height="100%"
            >
              <Scrollbars universal autoHide>
                <Flex justifyContent="center" alignItems="center" width="100%" height="100%">
                  <Flex flexDirection="column" justifyContent="space-between" height="100%" maxHeight="800px">
                    <Flex flexDirection="column" width="100%">
                      <Flex
                        mt="xl"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                        width={{ mobile: '300px', tablet: '370px' }}
                        mx="auto"
                      >
                        <Link href="/" rel="noopener noreferrer nofollow" title="Vista Social">
                          <Icon.VistaSocialTextLogo
                            width="224px"
                            height="34.88px"
                            fill="denimText"
                            viewBox="0 0 122 19"
                          />
                        </Link>

                        {otp ? (
                          <Fragment>
                            <Flex mt="xl" flexDirection="column" alignItems="center">
                              <Text fontSize="xl" color="denimText" fontWeight="bold">
                                Please enter verification code
                              </Text>
                              <Text fontSize="l" color="denimText">
                                On your mobile device, get a verification code from the Authenticator app. Use that
                                here.
                              </Text>
                            </Flex>

                            {error && (
                              <Text mt="l" color="error" fontSize="m">
                                {error}
                              </Text>
                            )}

                            <Box mt={error ? 'xs' : 'l'} width="100%">
                              <Formik
                                initialValues={{ [CODE]: '' }}
                                onSubmit={this.verifyCode}
                                validationSchema={OtpValidationSchema}
                                ref={this.formRef}
                              >
                                {({ values, handleSubmit, handleChange, errors, touched }) => (
                                  <Box mt="m">
                                    <VerificationCodeInput
                                      value={values[CODE]}
                                      onChange={handleChange(CODE)}
                                      onCompleted={() => {
                                        setTimeout(handleSubmit, 0)
                                      }}
                                      error={errors[CODE] && touched[CODE] && errors[CODE]}
                                    />

                                    <Box mt="l">
                                      <StyledButtonWithLoading
                                        onClick={(e) => {
                                          e.preventDefault()
                                          handleSubmit()
                                        }}
                                        type="submit"
                                        width="100%"
                                        isLoading={isSubmitting}
                                      >
                                        <Text fontSize="m">{isSubmitting ? 'Verifying' : 'Verify'}</Text>
                                      </StyledButtonWithLoading>
                                    </Box>
                                  </Box>
                                )}
                              </Formik>
                            </Box>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Flex mt="xl" flexDirection="column" alignItems="center">
                              <Text fontSize="xl" color="denimText" fontWeight="bold">
                                Welcome back,
                              </Text>
                              <Text fontSize="l" color="denimText">
                                good to see you again!
                              </Text>
                            </Flex>

                            {error && (
                              <Text mt="m" mb="m" color="error" fontSize="m">
                                {error}
                              </Text>
                            )}

                            <Box mt={error ? 'xs' : 'l'} width="100%">
                              <Formik
                                initialValues={{ [EMAIL]: '', [PASSWORD]: '' }}
                                onSubmit={this.handleSubmit}
                                validationSchema={ValidationSchema}
                              >
                                {({ values, handleSubmit, handleChange, errors, touched }) => (
                                  <StyledForm onSubmit={this.handleSubmit}>
                                    <StyledInput
                                      isLarge
                                      placeholder="Email"
                                      type="email"
                                      value={values[EMAIL]}
                                      name={[EMAIL]}
                                      onChange={handleChange(EMAIL)}
                                      error={errors[EMAIL] && touched[EMAIL] && errors[EMAIL]}
                                      autoComplete=""
                                    />
                                    <StyledInput
                                      isLarge
                                      placeholder="Password"
                                      value={values[PASSWORD]}
                                      name={[PASSWORD]}
                                      onChange={handleChange(PASSWORD)}
                                      error={errors[PASSWORD] && touched[PASSWORD] && errors[PASSWORD]}
                                      type="password"
                                      mt="m"
                                      autoComplete=""
                                    />
                                    <Flex mt="m" justifyContent="flex-end">
                                      <Link fontSize="xs" color="primary" href="/password">
                                        Forgot password?
                                      </Link>
                                    </Flex>

                                    <Box mt="l">
                                      <StyledButtonWithLoading
                                        onClick={(e) => {
                                          e.preventDefault()
                                          handleSubmit()
                                        }}
                                        type="submit"
                                        width="100%"
                                        isLoading={isSubmitting}
                                      >
                                        <Text fontSize="m">{isSubmitting ? 'Submitting' : 'Login'}</Text>
                                      </StyledButtonWithLoading>
                                    </Box>

                                    <GoogleLogin />
                                  </StyledForm>
                                )}
                              </Formik>
                              {isCaptchaEnabled && (
                                <ReCAPTCHA
                                  sitekey="6Ld6gM0gAAAAAPwkW5CO806wVq_C1BanZ12XeZyI"
                                  size="invisible"
                                  ref={this.recaptchaRef}
                                  onExpired={this.onRecaptchaExpired}
                                />
                              )}
                            </Box>
                          </Fragment>
                        )}
                      </Flex>

                      {otp ? (
                        <Flex my="l" justifyContent="center" alignItems="center">
                          <Text as={Link} fontSize="xs" color="primary" href="/api/logout">
                            Logout
                          </Text>
                        </Flex>
                      ) : (
                        <Flex my="l" justifyContent="center" alignItems="center">
                          <Text fontSize="xs" color="secondaryText">
                            Don't have an account?
                          </Text>
                          &nbsp;
                          <Text as={Link} fontSize="xs" color="primary" href="/pricing">
                            Create an account
                          </Text>
                        </Flex>
                      )}
                    </Flex>

                    <Flex alignItems="center" color="secondaryText" mx="auto" pb="l">
                      {FooterTemplate}
                    </Flex>
                  </Flex>
                </Flex>
              </Scrollbars>
            </Flex>

            {FEATURE.isVisible && (
              <StyledFeatureWrapper
                display={{ mobile: 'none', tablet: 'flex' }}
                width="50%"
                position="relative"
                onClick={() => {
                  if (FEATURE.link) {
                    window.open(FEATURE.link, '_blank')
                  }
                }}
                hasCursor={FEATURE.link}
              >
                <BgImg src={FEATURE.image} alt={FEATURE.title} />
                <Flex
                  flexDirection="column"
                  zIndex="1"
                  maxWidth="760px"
                  // maxWidth="580px"
                  width="100%"
                  position="absolute"
                  top="0"
                  left="0"
                  pl={{ tablet: '45px', desktop: '90px' }}
                  pt="70px"
                >
                  {FEATURE.isNew && (
                    <Flex>
                      <StyledNewFeatureWrapper px="m" py="xs">
                        <Text color="primary" fontWeight="medium">
                          New Feature
                        </Text>
                      </StyledNewFeatureWrapper>
                    </Flex>
                  )}
                  <Text mt="l" fontWeight="bold" fontSize="xxxxl" color="white">
                    {FEATURE.title}
                  </Text>
                  <Text mt="s" pt="xs" fontSize="m" color="white">
                    {FEATURE.description}
                  </Text>
                </Flex>
              </StyledFeatureWrapper>
            )}
          </Flex>
        </FullScreen>
      )
    } else {
      return null
    }
  }
}

Login.defaultProps = {
  loggedIn: false,
  isCaptchaEnabled: true,
  otp: false,
}

Login.propTypes = {
  loggedIn: PropTypes.bool,
  isCaptchaEnabled: PropTypes.bool,
  otp: PropTypes.bool,
}

Login.displayName = 'Login'

export default withMeta({
  path: '/login/',
  title: 'Log In | Vista Social',
  description: "Log in to your Vista Social account. Don't have an account yet? Create an account!",
})(withGoogleLogin(Login))
