/* eslint-disable prefer-destructuring */
import React from 'react'
import Cookies from 'js-cookie'
import request from 'utils/request'
import { getTimeZone } from 'helpers'

const withGoogleLogin = (Comp) => {
  // eslint-disable-next-line react/prefer-stateless-function

  class GoogleLoginComponent extends React.Component {
    /*
    static getInitialProps = async (context) => {
      try {
        await Comp.getInitialProps(context)
      } catch (error) {
        console.error(`unable to generate inital props ${error.stack}`)
      }
    }
    */

    componentDidMount() {
      try {
        let tries = 0

        const launchOneTap = () => {
          if (++tries >= 20) {
            return
          }
          setTimeout(() => {
            if (typeof google !== 'undefined') {
              const onOneTapSignedIn = async (response) => {
                response.timezone = getTimeZone()

                const res = await request({
                  path: 'connect/google/credentials',
                  method: 'POST',
                  body: response,
                })

                if (res.token) {
                  await Cookies.set('jwt', res.token, { secure: true })

                  document.location = res.redirect || '/dashboard'
                }
              }

              google.accounts.id.initialize({
                client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
                callback: onOneTapSignedIn,
              })

              google.accounts.id.prompt((notification) => {
                // console.log('notification', notification)
                if (notification.isDisplayed()) {
                  // console.log('showing google login prompt')
                } else if (notification.isNotDisplayed()) {
                  console.log(notification.getNotDisplayedReason())
                } else if (notification.isSkippedMoment()) {
                  console.log(notification.getSkippedReason())
                } else if (notification.isDismissedMoment()) {
                  console.log(notification.getDismissedReason())
                } else {
                  console.log('unsupported google login display', notification.getMomentType())
                }
              })
            } else {
              console.log('no google', tries)

              launchOneTap()
            }
          }, 100)
        }

        launchOneTap()
      } catch (error) {
        console.error('unexpected google login error', error)
      }
    }

    render() {
      return <Comp {...this.props} />
    }
  }

  return GoogleLoginComponent
}

export default withGoogleLogin
